import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

export const showToast = (message, type, duration = 5000) => {
  switch (type) {
    case "success":
      toast.dismiss();
      toast.success(message, {
        autoClose: duration,
        closeOnClick: false,
        pauseOnHover: false,
        draggable: false,
        closeButton: true,
      });

      break;
    case "error":
      toast.dismiss();
      toast.error(message, {
        autoClose: duration,
        closeOnClick: false,
        pauseOnHover: false,
        draggable: false,
        closeButton: true,
      });
      break;
    case "warning":
      toast.dismiss();
      toast.warning(message, {
        autoClose: duration,
        closeOnClick: false,
        pauseOnHover: false,
        draggable: false,
        closeButton: true,
      });

      break;
    default:
      toast.dismiss();
      toast.info(message, {
        autoClose: duration,
        closeOnClick: false,
        pauseOnHover: false,
        draggable: false,
        closeButton: true,
      });
  }
};
