import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { axiosInstance } from "../../utils/commonInstance";
import { showToast } from "../../hooks/showToast";
import { checkObjectForNullUndefinedOrEmpty } from "../../utils/commonFunction";
import { BackButtonModal } from "../../components/BackButtonModal";

const schema = yup.object().shape({
  company_id: yup.string().required("Please select Company"),
  location_id: yup.string().required("Please select Location"),
  //   company_longitude: yup
  //     .string()
  //     .required("Kindly enter Longitude")
  //     .max(20, "Maximum 20 character is allowed")
  //     .test("no-spaces-at-ends", "Please Enter Valid Input", (value) => {
  //       if (!value) return true; // Skip empty values (use required() to handle this)
  //       return value.trim() === value;
  //     }),
  //   company_latitude: yup
  //     .string()
  //     .required("Kindly enter Latitude")
  //     .max(20, "Maximum 20 character is allowed")
  //     .test("no-spaces-at-ends", "Please Enter Valid Input", (value) => {
  //       if (!value) return true; // Skip empty values (use required() to handle this)
  //       return value.trim() === value;
  //     }),
  company_longitude: yup
    .string()
    .required("Kindly enter Longitude")
    .test("is-valid-longitude", "Please enter a valid Longitude", (value) => {
      if (!value) return true; 
      const longitude = parseFloat(value);
      return !isNaN(longitude) && longitude >= -180 && longitude <= 180;
    }),
  company_latitude: yup
    .string()
    .required("Kindly enter Latitude")
    .test("is-valid-latitude", "Please enter a valid Latitude", (value) => {
      if (!value) return true; 
      const latitude = parseFloat(value);
      return !isNaN(latitude) && latitude >= -90 && latitude <= 90;
    }),
});

const CompanyLocationForm = ({
  toggleFormFillrter,
  manualFetchData,
  setGlobalFilter,
  isView,
  isEdit,
  previousData,
}) => {
  const [companyOption, setCompanyOption] = useState([]);
  const [locationOption, setLocationOption] = useState([]);

  const [modal, setModal] = useState({
    isOpen: false,
  });

  const handleModalopen = () => {
    setModal((prevData) => ({
      ...prevData,
      isOpen: true,
    }));
  };

  const handleClose = () => {
    setModal((prevData) => ({
      ...prevData,
      isOpen: false,
    }));
  };

  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
    watch,
    reset,
    setValue,
  } = useForm({
    resolver: yupResolver(schema),
    mode: "all",
  });

  useEffect(() => {
    async function fetchData() {
      // You can await here
      const response = await axiosInstance.get(`web/companydropdown`);
      setCompanyOption(response?.data?.data);
      // ...
    }
    fetchData();
  }, []);

  // const selectedCompanyId = watch("company_id");
  // useEffect(() => {
  //   if (selectedCompanyId) {
  //     // Fetch locations based on selected company_id if needed
  //     async function fetchLocations() {
  //       const response = await axiosInstance.get(
  //         `web/locationdata/${selectedCompanyId}`
  //       );
  //       setLocationOption(response?.data?.data);
  //     }
  //     fetchLocations();
  //   }
  // }, [selectedCompanyId]);

  useEffect(() => {
    async function fetchData() {
      // You can await here
      const response = await axiosInstance.get(`web/locationdropdown`);
      setLocationOption(response?.data?.data);
      // ...
    }
    fetchData();
  }, []);

  const onSubmit = async (data) => {
    try {
      // const response = await axios.post(process.env.REACT_APP_BACKEND_API_URL + "login", {...data,'mode_of_login':'Web'})

      const endPoint = isEdit
        ? `editcompany/${previousData?.id}`
        : "addcompany";

      const response = await axiosInstance.post(`web/${endPoint}`, {
        ...data,
      });

      if (response?.data?.statusCode !== 200) {
        showToast(response?.data?.message, "error");
        return false;
      }
      manualFetchData();
      toggleFormFillrter();
      showToast(response?.data?.message, "success");
    } catch (error) {
      showToast(error?.data?.message, "error");
    }
  };

  useEffect(() => {
    if (isView || isEdit) {
      setValue("company_id", previousData?.company_id);
      setValue("location_id", previousData?.location_id);
      setValue("checkpoint_name", previousData?.checkpoint_name);
      setValue("company_longitude", previousData?.company_longitude);
      setValue("company_latitude", previousData?.company_latitude);
      setValue("is_active", previousData?.is_active);
    }
  }, [isEdit, isView, previousData, setValue, companyOption, locationOption]);

  let result = checkObjectForNullUndefinedOrEmpty(watch());

  return (
    <>
      <section className="userlist-newuser content mobile">
        <div className="d-flex justify-content-end align-items-center mb-4 gap-3 flex-wrap">
          <h3 className="mb-0">
            <strong>
              {isEdit ? "Update" : isView ? "View" : "Add"} Company Location
              Details
            </strong>
          </h3>
          <button
            onClick={() =>
              result && !isView ? handleModalopen() : toggleFormFillrter()
            }
            class="btn btn-lg btn-primary px-md-5 px-4 addlistuser"
          >
            Back
          </button>
        </div>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="user-details">
            <div className="card">
              <div className="row card-body  py-md-5 py-4 align-items-center">
                <div className="col-lg-5 col-md-6 mb-3">
                  <div className="form-group">
                    <label htmlFor="company_id" className="form-label">
                      Select Company<span className="text-danger">*</span>
                    </label>
                    <select
                      className="form-select py-2 w-100"
                      {...register("company_id")}
                      disabled={isView}
                      defaultValue=""
                    >
                      <option value="" disabled>
                        Company
                      </option>
                      {companyOption?.map((ele) => (
                        <option value={ele?.id}>{ele?.company_name}</option>
                      ))}
                    </select>
                    {errors?.company_id && (
                      <p className="text-danger">
                        {errors?.company_id?.message}
                      </p>
                    )}
                  </div>
                </div>

                <div className="col-lg-5 col-md-6 mb-3">
                  <div className="form-group">
                    <label htmlFor="location_id" className="form-label">
                      Select Location<span className="text-danger">*</span>
                    </label>
                    <select
                      className="form-select py-2 w-100"
                      {...register("location_id")}
                      disabled={isView}
                      defaultValue=""
                    >
                      <option value="" disabled>
                        Location
                      </option>
                      {locationOption?.map((ele) => (
                        <option value={ele?.id}>{ele?.location_name}</option>
                      ))}
                    </select>
                    {errors?.location_id && (
                      <p className="text-danger">
                        {errors?.location_id?.message}
                      </p>
                    )}
                  </div>
                </div>

                <div className="col-lg-5 col-md-6">
                  <div className="form-group mb-3">
                    <label htmlFor="salary" className="form-label">
                      Company Longitude<span className="text-danger">*</span>
                    </label>
                    <input
                      type="text"
                      className="form-control py-2"
                      id="company_longitude"
                      placeholder="Enter Company Longitude"
                      {...register("company_longitude")}
                      disabled={isView}
                    />
                    {errors.company_longitude && (
                      <p className="text-danger">
                        {errors.company_longitude.message}
                      </p>
                    )}
                  </div>
                </div>

                <div className="col-lg-5 col-md-6">
                  <div className="form-group mb-3">
                    <label htmlFor="company_latitude" className="form-label">
                      Company Latitude<span className="text-danger">*</span>
                    </label>
                    <input
                      type="text"
                      className="form-control py-2"
                      id="salcompany_latitudeary"
                      placeholder="Enter Company Latitude"
                      {...register("company_latitude")}
                      disabled={isView}
                    />
                    {errors.company_latitude && (
                      <p className="text-danger">
                        {errors.company_latitude.message}
                      </p>
                    )}
                  </div>
                </div>

                <div class="col-lg-5 col-md-6">
                  <div class="form-group mt-md-3 mb-3">
                    <div class="form-check">
                      <input
                        class="form-check-input mt-1"
                        type="checkbox"
                        value=""
                        id="flexCheckChecked"
                        {...register("is_active")}
                        disabled={isView}
                        defaultChecked={!isView || !isEdit}
                      />
                      <label
                        class="form-check-label mb-0"
                        for="flexCheckChecked"
                      >
                        IS ACTIVE
                      </label>
                      <p className="text-danger">{errors.is_active?.message}</p>
                    </div>
                  </div>
                </div>

                <div className="col-12 mt-lg-5 mt-4 d-sm-inline-flex justify-content-sm-center d-grid gap-3">
                  <button
                    className="btn btn-lg btn-primary px-5"
                    type="submit"
                    disabled={isView}
                  >
                    {isEdit ? "UPDATE" : "SUBMIT"}
                  </button>
                  <button
                    class="btn btn-lg btn-primary px-5 mx-xl-4"
                    type="button"
                    disabled={isView || isEdit}
                    onClick={() => {
                      reset();
                      setLocationOption([]);
                    }}
                  >
                    CLEAR
                  </button>
                </div>
              </div>
            </div>
          </div>
        </form>
        <BackButtonModal
          isOpen={modal?.isOpen}
          handleClose={handleClose}
          toggleFormFillrter={toggleFormFillrter}
        />
      </section>
    </>
  );
};

export default CompanyLocationForm;
