/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useMemo, useEffect, useRef } from "react";
import HeaderLayout from "../../layouts/admin/HeaderLayout";
import FooterLayout from "../../layouts/admin/FooterLayout";
import Listfilter from "../../components/ListFilter";
import MaterialDataGrid from "../../components/DataTable";
import { axiosInstance } from "../../utils/commonInstance";
import useDataFetching from "../../hooks/useDataFatching";
import { Box, IconButton, Modal, Typography } from "@mui/material";

import { handleExportData } from "../../components/DataTable";
import { useForm, Controller } from "react-hook-form";
import { showToast } from "../../hooks/showToast";
import Multiselect from "multiselect-react-dropdown";
import DateRangePicker from "@wojtekmaj/react-daterange-picker";
import moment from "moment";
import "@wojtekmaj/react-daterange-picker/dist/DateRangePicker.css";
import "react-calendar/dist/Calendar.css";
import * as XLSX from "xlsx";

import { AltRoute, Close } from "@mui/icons-material";
import { useLocation } from "react-router-dom";
import {
  addSerialNumber,
  changeArrayKeys,
  createHeaderMap,
  extractKeysFromArray,
  flattenObject1,
  flattenObjectForReportsPage,
  transformData,
} from "../../utils/commonFunction";
import { Speaker } from "feather-icons-react";

const fetchDataForPlatform = async (params, endpoint) => {
  return await axiosInstance.post(`web/${endpoint}`, { params });
};

const CommonReportsList = ({
  title,
  dynamiColumns,
  endPoint,
  columnOrder,
  excludeKeys,
  exportColumns,
  keyMapping,
  toggleFormFillrter,
  filterOption,
  isExport = false,
  data,
}) => {
  const location = useLocation();
  const {
    state,
    columnFilters,
    setColumnFilters,
    setGlobalFilter,
    // pagination,
    // setPagination,
    manualFetchData,
  } = useDataFetching(fetchDataForPlatform, endPoint, title);
  const [calculations, setCalculations] = useState(0);
  const [pagination, setPagination] = useState({
    pageIndex: 1,
    pageSize: 10,
  });

  const handlePreviewClick = (row) => {
    // Add your edit logic here
  };

  useEffect(() => {
    // Assuming OpenNewPagination is an object with properties pageIndex and pageSize
    const calculation = pagination.pageIndex * pagination.pageSize;
    // Set test to 0 if OpenNewPagination.pageIndex is 0, otherwise use the calculation
    setCalculations(calculation);
  }, [pagination.pageIndex, pagination.pageSize]);

  const dynamicColumns = useMemo(() => {
    const columns = [];
    // Add dynamic columns
    columns.push({
      header: "Sr. No.",
      Cell: ({ row }) => {
        const number = calculations + (row?.index + 1);
        const formattedNumber = number < 10 ? `0${number}` : number;
        return <div className="sr-no">{formattedNumber}</div>;
      },
      maxSize: 15,
      minSize: 10,
    });

    dynamiColumns?.forEach((col) => {
      if (col.accessorKey === "preview") {
        // Customize the Cell renderer for the actions column
        columns.push({
          ...col,
          Cell: ({ row }) => (
            <div onClick={() => handlePreviewClick(row?.original)}>
              <a role="button" className="text-decoration-underline">
                Preview
              </a>
            </div>
          ),
        });
      } else if (col.accessorKey === "map") {
        // Customize the Cell renderer for the actions column
        columns.push({
          ...col,
          Cell: ({ row }) => (
            <div onClick={() => handlePreviewClick(row?.original)}>
              <a role="button" className="text-decoration-underline">
                Map
              </a>
            </div>
          ),
        });
      } else if (col.accessorKey === "voicenote") {
        // Customize the Cell renderer for the actions column
        columns.push({
          ...col,
          Cell: ({ row }) => {
            // Extract the relevant data from row.original
            const recordingId = row?.original?.alert_audio_url; // or whatever key holds the dynamic part

            return (
              <a
                href={recordingId}
                className="text-decoration-underline"
                target="_blank"
                rel="noopener noreferrer"
              >
                <Speaker />
              </a>
            );
          },
        });
      } else {
        columns.push(col);
      }
    });
    return columns;
  }, [dynamiColumns, calculations]);

  // Define a function to remove specific items from the array
  function removeItems(array, itemsToRemove) {
    return array.filter((item) => !itemsToRemove.includes(item));
  }

  const finalArray = extractKeysFromArray(
    state?.data,
    removeItems(columnOrder, excludeKeys)
  );
  const newArray = changeArrayKeys(finalArray, keyMapping);

  newArray &&
    newArray?.forEach((obj) => {
      delete obj.Shift;
    });

  useEffect(() => {
    setPagination({ pageIndex: 0, pageSize: 10 });
  }, [endPoint, title]);

  const [fillter, setFillter] = useState({ selectFillter: "", search: "" });
  const [selectFilter, setSelectFilter] = useState(null);
  const [roleRightsData, setRoleRightsData] = useState([]);
  const [value, onChange] = useState([]);
//   const [value, onChange] = useState([
//     new Date(new Date().getFullYear(), new Date().getMonth(), 1),
//     new Date(),
//   ]);

  const multiselectRef = React.createRef();
  const [companyOption, setCompanyOption] = useState([]);
  const [locationOption, setLocationOption] = useState([]);
  const [vendorOption, setVendorOption] = useState([]);
  const [securityGuardOption, setSecurityGuardOption] = useState([]);
  const [selectedCompanyOptions, setSelectedCompanyOptions] = useState([]);
  const [selectedLocationOptions, setSelectedLocationOptions] = useState([]);
  const [selectedVendorOptions, setSelectedVendorOptions] = useState([]);
  const [selectedSecurityGuardOptions, setSelectedSecurityGuardOptions] =
    useState([]);
  const startDate = moment(value[0]).format("YYYY-MM-DD");
  const EndDate = moment(value[1]).format("YYYY-MM-DD");

  const {
    register,
    handleSubmit,
    reset,
    control,
    setValue,
    formState: { errors },
    watch,
  } = useForm({});

  const roleId = localStorage.getItem("role_id");

  useEffect(() => {
    async function fetchData() {
      try {
        const response = await axiosInstance.get(
          `web/getrolerightsdata/${roleId}`
        );
        const rightsData = response?.data?.data || [];
        setRoleRightsData(rightsData);
        rightsData.forEach((right) => {
          setValue(right, true);
        });
      } catch (error) {
        showToast("Error fetching role rights data", "error");
      }
    }
    if (roleId) {
      fetchData();
    }
  }, [roleId, setValue]);

  const pagePermissions = {
    "/userlist": ["user.add"],
    "/companymasterlist": ["company.add"],
    "/locationList": ["location.add"],
    "/companylist": ["companylocation.add"],
    "/vendorlist": ["vendor.add"],
    "/shiftlist": ["shift.add"],
    "/designationlist": ["designation.add"],
    "/checkpointlist": ["checkpoint.add"],
    "/roleList": ["role.add"],
    "/apkConfiguration": ["appconfig.add"],
    "/attendanceReports": ["attendance.export"],
    "/getfire": ["firealertreport.export"],
    "/getLockReports": ["gatelockreport.export"],
    "/getTrespassing": ["trespassingreport.export"],
    "/getpanicmode": ["panicmodereport.export"],
    "/getsalaryreport": ["salaryreport.export"],
  };
  const checkPermission = (roleRightsData, permission) =>
    !roleRightsData.includes(permission);
  const currentPath = location.pathname;
  const requiredPermissions = pagePermissions[currentPath] || [];

  const [gridData, setGridData] = useState({
    data: [],
    rowCount: 0,
    isError: false,
    isLoading: false,
    isRefetching: false,
  });

  useEffect(() => {
    const fetchInitialData = async () => {
      try {
        setGridData((prev) => ({ ...prev, isLoading: true }));

        // const today = new Date();
        // const last30Days = new Date();
        // last30Days.setDate(today.getDate() - 30);

        const response = await axiosInstance.post(
        //   `web/getNotificationsAllReport?limit=${pagination?.pageSize}&page=${
          `web/${endPoint}?limit=${pagination?.pageSize}&page=${
            pagination?.pageIndex + 1
          }`,
          {
            alert_type: location?.pathname === "/getpanicmode" ? "panic mode" : location?.pathname === "/getfire" ? "fire" : "trespassing",
            // company_id: ["All"],
            // locationId: ["All"],
            // vendorId: ["All"],
            // userId: ["All"],
            // from: last30Days,
            // to: today,
          }
        );

        if (!response?.data?.error) {
          const initialData = response?.data?.data;
          const totalRows = response.data.totalRecords || response.totalRecords;
          setGridData({
            data: initialData,
            // rowCount: initialData.length,
            rowCount: totalRows,
            isError: false,
            isLoading: false,
            isRefetching: false,
          });
        } else {
          showToast(response?.data?.message, "error");
          setGridData((prev) => ({ ...prev, isError: true, isLoading: false }));
        }
      } catch (error) {
        showToast("Error fetching data", "error");
        setGridData((prev) => ({ ...prev, isError: true, isLoading: false }));
      }
    };

    if (
      !changeCompanyData?.length &&
      !changeLocationData?.length &&
      !selectedSecurityGuardOptions?.length
    ) {
      fetchInitialData();
    }
  }, [pagination]);

  const handleFilterSubmit = async () => {
    if (!selectedCompanyOptions?.length) {
      showToast("Please Select Company Name", "error");
      return;
    }
    if (!selectedLocationOptions?.length) {
      showToast("Please Select Location", "error");
      return;
    }
    if (!selectedSecurityGuardOptions?.length) {
      showToast("Please Select Security Guard", "error");
      return;
    }
    if (!value.length || !value[0] || !value[1]) {
        showToast("Please Select Date", "error");
        return;
      }
    
      const startDate = moment(value[0], "DD-MM-YYYY", true);
      const endDate = moment(value[1], "DD-MM-YYYY", true);
    
      if (!startDate.isValid() || !endDate.isValid()) {
        showToast("Invalid date format. Use DD-MM-YYYY", "error");
        return;
      }
    
      if (startDate.year().toString().length !== 4 || endDate.year().toString().length !== 4) {
        showToast("Year must be 4 digits (DD-MM-YYYY)", "error");
        return;
      }

    await fetchFilteredData();
  };

  const fetchFilteredData = async () => {
    const data = {
      alert_type: location?.pathname === "/getpanicmode" ? "panic mode" : location?.pathname === "/getfire" ? "fire" : "trespassing",
      company_id: changeCompanyData,
      locationId: changeLocationData,
      userId: selectedSecurityGuardOptions.map((item) => item.id),
      from: startDate,
      to: EndDate,
    };
    try {
      setGridData((prev) => ({ ...prev, isLoading: true }));

      const response = await axiosInstance.post(
        `web/${endPoint}?limit=${pagination?.pageSize}&page=${
          pagination?.pageIndex + 1
        }`,
        data
      );

      if (!response?.data?.error) {
        const filteredData = response?.data?.data;
        const totalRows = response.data.totalRecords || response.totalRecords;
        setGridData({
          data: filteredData,
          rowCount: totalRows,
          isError: false,
          isLoading: false,
          isRefetching: false,
        });
      } else {
        showToast(response?.data?.message, "error");
        setGridData((prev) => ({ ...prev, isError: true, isLoading: false }));
      }
    } catch (error) {
      showToast("Error fetching data", "error");
      setGridData((prev) => ({ ...prev, isError: true, isLoading: false }));
    }
  };

  useEffect(() => {
    if (
      changeCompanyData?.length > 0 &&
      changeLocationData?.length > 0 &&
      selectedSecurityGuardOptions?.length > 0
    ) {
      fetchFilteredData();
    }
  }, [pagination]);

  useEffect(() => {
    async function fetchCompany() {
      const response = await axiosInstance.get(`web/companyallfilter`);
      if (!response?.data?.error) {
        const formattedOptions = response?.data?.data?.map((ele) => ({
          id: ele?.id,
          name: ele?.company_name,
        }));
        setCompanyOption(formattedOptions);
      } else {
        setCompanyOption([]);
        // showToast(response?.data?.message, "error");
      }
    }
    fetchCompany();
  }, []);

  const changeCompanyData = watch("company_id");
  const changeLocationData = watch("location_id");
  useEffect(() => {
    if (changeCompanyData) {
      if (changeCompanyData?.length === 0) {
        setSelectedLocationOptions([]);
        setSelectedVendorOptions([]);
        setSelectedSecurityGuardOptions([]);
      }

      async function fetchLocations() {
        const params = {
          company_id: changeCompanyData,
        };
        console.log(params);
        try {
          const response = await axiosInstance.post(`web/locationallfilter`, {
            ...params,
            mode_of_login: "Web",
          });
          if (response?.data?.statusCode !== 200) {
            return false;
          }
          const formattedOptions = response?.data?.data?.map((ele) => ({
            id: ele?.id,
            name: ele?.name,
          }));
          setLocationOption(formattedOptions);
        } catch (error) {
          //   showToast(error?.data?.message, "error");
        }
      }
      fetchLocations();
    }    

    if (changeLocationData) {
      if (changeLocationData?.length === 0) {
        setSelectedSecurityGuardOptions([]);
      }
      async function fetchSecurityGuards() {
        const params = {
          company_id: changeCompanyData,
          locationId: changeLocationData,
        };
        console.log(params);
        try {
          const response = await axiosInstance.post(`web/userallfilter`, {
            ...params,
            mode_of_login: "Web",
          });
          if (response?.data?.statusCode !== 200) {
            return false;
          }
          const formattedOptions = response?.data?.data?.map((ele) => ({
            id: ele?.id,
            name: ele?.username,
          }));
          setSecurityGuardOption(formattedOptions);
        } catch (error) {
          //   showToast(error?.data?.message, "error");
        }
      }
      fetchSecurityGuards();
    }
  }, [changeCompanyData, changeLocationData]);

  const handleSelect = (
    selectedList,
    selectedItem,
    optionList,
    setSelectedOptions,
    setFieldValue,
    fieldName
  ) => {
    if (selectedItem?.id === "All") {
      const ids = optionList.map((option) => option.id);
      setSelectedOptions(optionList);
      setFieldValue(fieldName, ids);
    } else {
      const allSelected = selectedList.some((item) => item.id === "All");
      if (allSelected) {
        const newList = selectedList.filter((item) => item.id !== "All");
        const ids = newList.map((option) => option.id);
        setSelectedOptions(newList);
        setFieldValue(fieldName, ids);
      } else {
        if (optionList?.length - 1 === selectedList?.length) {
          const ids = optionList.map((option) => option.id);
          setSelectedOptions(optionList);
          setFieldValue(fieldName, ids);
        } else {
          const ids = selectedList.map((option) => option.id);
          setSelectedOptions(selectedList);
          setFieldValue(fieldName, ids);
        }
      }
    }
  };

  const handleRemove = (
    selectedList,
    removedItem,
    setSelectedOptions,
    setFieldValue,
    fieldName
  ) => {
    if (removedItem?.id === "All") {
      setSelectedOptions([]);
      setFieldValue(fieldName, []);
    } else {
      const allSelected = selectedList.some((item) => item.id === "All");
      if (allSelected) {
        const newList = selectedList.filter((item) => item.id !== "All");
        const ids = newList.map((option) => option.id);
        setSelectedOptions(newList);
        setFieldValue(fieldName, ids);
      } else {
        const ids = selectedList.map((option) => option.id);
        setSelectedOptions(selectedList);
        setFieldValue(fieldName, ids);
      }
    }
  };

    const handleExport = () => {
      const exportData = gridData?.data?.map((row, index) => ({
        "Sr. No.": calculations + (index + 1),
        // "Guard Code": row.guard_code,
        "Guard Name (Guard Code)": row.username,
        "Company Name": row.company_name,
        "Location": row.location_name,
        "Designation": row.designation_name,
        "Time": row.alert_created_time,
        "Date": row.alert_created_date,
        "Note": row.alert_message,
      }));
      
      const worksheet = XLSX.utils.json_to_sheet(exportData);
      const workbook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workbook, worksheet, "Reports");

    //   XLSX.writeFile(workbook, "Panic Mode Report.xlsx");
      XLSX.writeFile(workbook, `${location?.pathname === "/getpanicmode" ? "Panic Mode" : location?.pathname === "/getfire" ? "Fire Alert" : "Trespassing Alert"} Report.xlsx`);
    };

  return (
    <>
      <div className="main">
        <HeaderLayout title={title} />
        <section class="user-listmain content web">
          <>
            <div>
              <div class="mb-4 row gy-2">
                <div class="col-xxl-3 col-xl-4 col-sm-6">
                  <Multiselect
                    ref={multiselectRef}
                    options={companyOption}
                    selectedValues={selectedCompanyOptions}
                    displayValue="name"
                    placeholder={"Company"}
                    defaultValue=""
                    onSelect={(selectedList, selectedItem) =>
                      handleSelect(
                        selectedList,
                        selectedItem,
                        companyOption,
                        setSelectedCompanyOptions,
                        setValue,
                        "company_id"
                      )
                    }
                    onRemove={(selectedList, removedItem) =>
                      handleRemove(
                        selectedList,
                        removedItem,
                        setSelectedCompanyOptions,
                        setValue,
                        "company_id"
                      )
                    }
                    avoidHighlightFirstOption={true}
                    hidePlaceholder={true}
                    showCheckbox={true}
                    name="company_id"
                    id="company_id"
                    {...register("company_id")}
                    style={{
                      searchBox: {
                        height:
                          selectedCompanyOptions?.length >= 3
                            ? "100px"
                            : "40px",
                        overflowY:
                          selectedCompanyOptions?.length >= 3
                            ? "scroll"
                            : "auto",
                      },
                    }}
                  />
                </div>
                <div class="col-xxl-3 col-xl-4 col-sm-6">
                  <Multiselect
                    ref={multiselectRef}
                    options={locationOption}
                    selectedValues={selectedLocationOptions}
                    displayValue="name"
                    placeholder={"Location"}
                    defaultValue=""
                    onSelect={(selectedList, selectedItem) =>
                      handleSelect(
                        selectedList,
                        selectedItem,
                        locationOption,
                        setSelectedLocationOptions,
                        setValue,
                        "location_id"
                      )
                    }
                    onRemove={(selectedList, removedItem) =>
                      handleRemove(
                        selectedList,
                        removedItem,
                        setSelectedLocationOptions,
                        setValue,
                        "location_id"
                      )
                    }
                    avoidHighlightFirstOption={true}
                    hidePlaceholder={true}
                    showCheckbox={true}
                    name="location_id"
                    id="location_id"
                    {...register("location_id")}
                    style={{
                      searchBox: {
                        height:
                          selectedLocationOptions?.length >= 3
                            ? "100px"
                            : "40px",
                        overflowY:
                          selectedLocationOptions?.length >= 3
                            ? "scroll"
                            : "auto",
                      },
                    }}
                  />
                </div>
                <div class="col-xxl-3 col-xl-4 col-sm-6">
                  <Multiselect
                    ref={multiselectRef}
                    options={securityGuardOption}
                    selectedValues={selectedSecurityGuardOptions}
                    displayValue="name"
                    placeholder={"Security Guard"}
                    defaultValue=""
                    onSelect={(selectedList, selectedItem) =>
                      handleSelect(
                        selectedList,
                        selectedItem,
                        securityGuardOption,
                        setSelectedSecurityGuardOptions,
                        setValue,
                        "security_guard_id"
                      )
                    }
                    onRemove={(selectedList, removedItem) =>
                      handleRemove(
                        selectedList,
                        removedItem,
                        setSelectedSecurityGuardOptions,
                        setValue,
                        "security_guard_id"
                      )
                    }
                    avoidHighlightFirstOption={true}
                    hidePlaceholder={true}
                    showCheckbox={true}
                    name="security_guard_id"
                    id="security_guard_id"
                    {...register("security_guard_id")}
                    style={{
                      searchBox: {
                        height:
                          selectedSecurityGuardOptions?.length >= 3
                            ? "100px"
                            : "40px",
                        overflowY:
                          selectedSecurityGuardOptions?.length >= 3
                            ? "scroll"
                            : "auto",
                      },
                    }}
                  />
                </div>
                <div class="col-xxl-3 col-xl-4 col-sm-6">
                  <DateRangePicker
                    maxDate={new Date()}
                    disableClock
                    value={value}
                    onChange={onChange}
                    format={"dd/MM/y"}
                    clearIcon={null}
                    className="w-100"
                  />
                </div>
                <div class="col-xxl-12 col-xl-4 col-sm-12 text-end">
                  <button
                    onClick={handleFilterSubmit}
                    class="btn btn-lg btn-primary px-3 h-100 me-2"
                  >
                    Apply Filter
                  </button>
                  <button
                      onClick={handleExport}
                    // onClick={() => {
                    //   if (data?.data?.length) handleExportData(data?.data);
                    // }}
                    class="btn btn-lg btn-primary px-3 h-100"
                    disabled={requiredPermissions.some((permission) =>
                      checkPermission(roleRightsData, permission)
                    )}
                  >
                    Export
                  </button>
                </div>
              </div>
            </div>
            <MaterialDataGrid
              columns={dynamicColumns}
              //   data={gridData.data?.data}
              data={gridData.data}
              rowCount={gridData.rowCount}
              isError={gridData.isError}
              handleColumnFilter={setColumnFilters}
              handlePagination={setPagination}
              initialState={{
                columnFilters: columnFilters,
                isLoading: gridData.isLoading,
                pagination: pagination,
                showAlertBanner: gridData.isError,
                showProgressBars: gridData.isRefetching,
                columnOrder: columnOrder ? columnOrder : [],
              }}
            />
          </>
        </section>
        <FooterLayout />
        <style jsx>{`
          .image-grid {
            display: grid;
            grid-template-columns: repeat(3, 1fr);
            gap: 10px;
          }
          .image-item {
            text-align: center;
          }
          .row {
            display: flex;
            justify-content: space-between;
          }
          .col {
            flex: 1 0 45%;
            margin: 10px;
            text-align: center;
          }
        `}</style>
      </div>
    </>
  );
};
export default CommonReportsList;
