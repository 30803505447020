import React, { useEffect, useState } from "react";
import Select from "react-select";
import { handleExportData } from "./DataTable";
import { useForm, Controller } from "react-hook-form";
import { axiosInstance } from "../utils/commonInstance";
import { showToast } from "../hooks/showToast";
import { useLocation } from "react-router-dom";
import Multiselect from "multiselect-react-dropdown";
import DateRangePicker from "@wojtekmaj/react-daterange-picker";
import moment from "moment";
import "@wojtekmaj/react-daterange-picker/dist/DateRangePicker.css";
import "react-calendar/dist/Calendar.css";

const Listfilter = ({
  toggleFormFillrter,
  setGlobalFilter,
  filterOption,
  title,
  isExport = false,
  data,
}) => {
  const [fillter, setFillter] = useState({ selectFillter: "", search: "" });
  const [selectFilter, setSelectFilter] = useState(null);
  const [roleRightsData, setRoleRightsData] = useState([]);
  const [value, onChange] = useState([new Date(), new Date()]);
  const multiselectRef = React.createRef();
  const [companyOption, setCompanyOption] = useState([]);
  const [locationOption, setLocationOption] = useState([]);
  const [vendorOption, setVendorOption] = useState([]);
  const [securityGuardOption, setSecurityGuardOption] = useState([]);
  const [selectedCompanyOptions, setSelectedCompanyOptions] = useState([]);
  const [selectedLocationOptions, setSelectedLocationOptions] = useState([]);
  const [selectedVendorOptions, setSelectedVendorOptions] = useState([]);
  const [selectedSecurityGuardOptions, setSelectedSecurityGuardOptions] =
    useState([]);
  const startDate = moment(value[0]).format("YYYY-MM-DD");
  const EndDate = moment(value[1]).format("YYYY-MM-DD");

  const {
    register,
    handleSubmit,
    reset,
    control,
    setValue,
    formState: { errors },
    watch,
  } = useForm({});

  const location = useLocation();
  const roleId = localStorage.getItem("role_id");

  useEffect(() => {
    async function fetchData() {
      try {
        const response = await axiosInstance.get(
          `web/getrolerightsdata/${roleId}`
        );
        const rightsData = response?.data?.data || [];       
        setRoleRightsData(rightsData);
        rightsData.forEach((right) => {
          setValue(right, true);
        });
      } catch (error) {
        showToast("Error fetching role rights data", "error");
      }
    }
    if (roleId) {
      fetchData();
    }
  }, [roleId, setValue]);

  const pagePermissions = {
    "/userlist": ["user.add"],
    "/companymasterlist": ["company.add"],
    "/locationList": ["location.add"],
    "/companylist": ["companylocation.add"],
    "/vendorlist": ["vendor.add"],
    "/shiftlist": ["shift.add"],
    "/designationlist": ["designation.add"],
    "/checkpointlist": ["checkpoint.add"],
    "/roleList": ["role.add"],
    "/apkConfiguration": ["appconfig.add"],
    "/attendanceReports": ["attendance.export"],
    "/getfire": ["firealertreport.export"],
    "/getLockReports": ["gatelockreport.export"],
    "/getTrespassing": ["trespassingreport.export"],
    "/getpanicmode": ["panicmodereport.export"],
    "/getsalaryreport": ["salaryreport.export"],
  };
  const checkPermission = (roleRightsData, permission) =>
    !roleRightsData.includes(permission);
  const currentPath = location.pathname;
  const requiredPermissions = pagePermissions[currentPath] || [];

  const handleFilterSubmit = async () => {
    const data = {
      company_id: changeCompanyData,
      locationId: changeLocationData,
      vendorId: changeVendorData,
      userId: securityGuardOption.map((item) => item.id),
      from: startDate,
      to: EndDate,
    };
    console.log(data);
    const response = await axiosInstance.post(`web/getsalaryreport`, data);
    if (!response?.data?.error) {
      console.log(response?.data?.data);
      const filteredData = response?.data?.data;
      setGlobalFilter(filteredData);
    } else {
      showToast(response?.data?.message, "error");
    }
  };

  useEffect(() => {
    async function fetchCompany() {
      const response = await axiosInstance.get(`web/companyallfilter`);
      if (!response?.data?.error) {
        const formattedOptions = response?.data?.data?.map((ele) => ({
          id: ele?.id,
          name: ele?.company_name,
        }));
        setCompanyOption(formattedOptions);
      } else {
        setCompanyOption([]);
        // showToast(response?.data?.message, "error");
      }
    }
    fetchCompany();
  }, []);

  const changeCompanyData = watch("company_id");
  const changeLocationData = watch("location_id");
  const changeVendorData = watch("vendor_id");
  useEffect(() => {
    if (changeCompanyData) {
      if (changeCompanyData?.length === 0) {
        setSelectedLocationOptions([]);
        setSelectedVendorOptions([]);
        setSelectedSecurityGuardOptions([]);
      }

      async function fetchLocations() {
        const params = {
          company_id: changeCompanyData,
        };
        console.log(params);
        try {
          const response = await axiosInstance.post(
            `web/locationallfilter`,
            {
              ...params,
              mode_of_login: "Web",
            }
          );
          if (response?.data?.statusCode !== 200) {
            return false;
          }
          const formattedOptions = response?.data?.data?.map((ele) => ({
            id: ele?.id,
            name: ele?.name,
          }));
          setLocationOption(formattedOptions);
        } catch (error) {
          //   showToast(error?.data?.message, "error");
        }
      }
      fetchLocations();
    }

    if (changeLocationData) {
      if (changeLocationData?.length === 0) {
        setSelectedVendorOptions([]);
        setSelectedSecurityGuardOptions([]);
      }

      async function fetchVendors() {
        const params = {
          company_id: changeCompanyData,
          locationId: changeLocationData,
        };
        console.log(params);
        try {
          const response = await axiosInstance.post(`web/vendorsalaryfilter`, {
            ...params,
            mode_of_login: "Web",
          });
          if (response?.data?.statusCode !== 200) {
            return false;
          }
          const formattedOptions = response?.data?.data?.map((ele) => ({
            id: ele?.id,
            name: ele?.vendor_name,
          }));
          setVendorOption(formattedOptions);
        } catch (error) {
          //   showToast(error?.data?.message, "error");
        }
      }
      fetchVendors();
    }

    if (changeVendorData) {
      if (changeVendorData?.length === 0) {
        setSelectedSecurityGuardOptions([]);
      }
      async function fetchSecurityGuards() {
        const params = {
          company_id: changeCompanyData,
          locationId: changeLocationData,
          vendorId: changeVendorData,
        };
        console.log(params);
        try {
          const response = await axiosInstance.post(`web/usersalaryfilter`, {
            ...params,
            mode_of_login: "Web",
          });
          if (response?.data?.statusCode !== 200) {
            return false;
          }
          const formattedOptions = response?.data?.data?.map((ele) => ({
            id: ele?.id,
            name: ele?.username,
          }));
          setSecurityGuardOption(formattedOptions);
        } catch (error) {
          //   showToast(error?.data?.message, "error");
        }
      }
      fetchSecurityGuards();
    }
  }, [changeCompanyData, changeLocationData, changeVendorData]);

  const handleSelect = (
    selectedList,
    selectedItem,
    optionList,
    setSelectedOptions,
    setFieldValue,
    fieldName
  ) => {
    if (selectedItem?.id === "All") {
      const ids = optionList.map((option) => option.id);
      setSelectedOptions(optionList);
      setFieldValue(fieldName, ids);
    } else {
      const allSelected = selectedList.some((item) => item.id === "All");
      if (allSelected) {
        const newList = selectedList.filter((item) => item.id !== "All");
        const ids = newList.map((option) => option.id);
        setSelectedOptions(newList);
        setFieldValue(fieldName, ids);
      } else {
        if (optionList?.length - 1 === selectedList?.length) {
          const ids = optionList.map((option) => option.id);
          setSelectedOptions(optionList);
          setFieldValue(fieldName, ids);
        } else {
          const ids = selectedList.map((option) => option.id);
          setSelectedOptions(selectedList);
          setFieldValue(fieldName, ids);
        }
      }
    }
  };

  const handleRemove = (
    selectedList,
    removedItem,
    setSelectedOptions,
    setFieldValue,
    fieldName
  ) => {
    if (removedItem?.id === "All") {
      setSelectedOptions([]);
      setFieldValue(fieldName, []);
    } else {
      const allSelected = selectedList.some((item) => item.id === "All");
      if (allSelected) {
        const newList = selectedList.filter((item) => item.id !== "All");
        const ids = newList.map((option) => option.id);
        setSelectedOptions(newList);
        setFieldValue(fieldName, ids);
      } else {
        const ids = selectedList.map((option) => option.id);
        setSelectedOptions(selectedList);
        setFieldValue(fieldName, ids);
      }
    }
  };

  useEffect(() => {
    if (title) {
      setSelectFilter(null);
      setFillter({ selectFillter: "", search: "" });
    }
  }, [title]);

  const handleFilterClear = () => {
    setSelectedCompanyOptions([]);
    setSelectedLocationOptions([]);
    setSelectedVendorOptions([]);
    setSelectedSecurityGuardOptions([]);
  };

  return (
    <>
    {/* single select start */}
      {location.pathname !== "/getsalaryreport" ? (
        <div className="mb-4 row gy-2 justify-content-start">
          <div className="col-xxl-2 col-xl-3 col-md-3">
            {location.pathname !== "/apkConfiguration" &&
            location.pathname !== "/getsalaryreport" ? (
              <Select
                onChange={(e) => {
                  setSelectFilter(e);
                  setFillter((prevState) => ({
                    ...prevState,
                    selectFillter: e?.value,
                  }));
                }}
                value={selectFilter}
                options={filterOption}
                placeholder="All"
                components={{ IndicatorSeparator: () => null }}
                styles={{
                  control: (base, state) => ({
                    ...base,
                    border: state.isFocused
                      ? "1px solid #222e3c"
                      : "1px solid #ced4da",
                    // This line disable the blue border
                    boxShadow: state.isFocused
                      ? "1px solid #222e3c"
                      : "1px solid #222e3c",
                    "&:hover": {
                      border: state.isFocused
                        ? "1px solid #222e3c"
                        : "1px solid #222e3c",
                    },
                  }),
                  menu: (provided, state) => ({
                    ...provided,
                    zIndex: 9999,
                  }),
                  menuPortal: (base) => ({
                    ...base,
                    zIndex: 9999, // your desired height
                  }),
                }}
              />
            ) : null}
          </div>
          <div className="col-xxl-2 col-xl-3 col-md-3">
            {location.pathname !== "/apkConfiguration" &&
            location.pathname !== "/getsalaryreport" ? (
              <input
                onChange={(e) => {
                  setFillter((prevState) => ({
                    ...prevState,
                    search: e?.target?.value,
                  }));
                }}
                onKeyDown={(e) => {
                    if (e.key === "Enter") {
                      setGlobalFilter(fillter); // Trigger search on Enter key
                    }
                  }}
                value={fillter?.search}
                type="search"
                id="search"
                name="search"
                className="form-control py-2 fw-medium"
                placeholder="Enter keyword"
              />
            ) : null}
          </div>
          <div className="col-xl-3 col-lg-4 col-auto col-xxl-2 text-start mt-sm-2 mt-3 d-flex gap-2">
            {location.pathname !== "/apkConfiguration" ? (
              <>
                <button
                  onClick={() => {
                    setGlobalFilter(fillter);                    
                  }}
                  className="btn btn-lg btn-primary px-3 h-100"
                >
                  Search
                </button>

                <button
                  onClick={() => {
                    setFillter({ selectFillter: "", search: "" });
                    setGlobalFilter({ selectFillter: "", search: "" });
                    setSelectFilter(null);
                  }}
                  className="btn btn-lg btn-primary px-3 h-100 ms-sm-2 ms-0"
                >
                  Clear
                </button>
              </>
            ) : null}
          </div>

          {/* <div className="col-lg-2 col-xl-3 col-auto col-xxl-6 text-start mt-sm-2 mt-3"> */}
          <div className="col-lg-2 col-xl-3 col-xxl-6 text-start mt-sm-2 mt-3">
            {!isExport ? (
              <button
                onClick={() => {
                  toggleFormFillrter(true);
                }}
                className="btn btn-lg float-end btn-primary px-3 h-100"
                disabled={requiredPermissions.some((permission) =>
                  checkPermission(roleRightsData, permission)
                )}
              >
                Add
              </button>
            ) : (
              <button
                onClick={() => {
                  if (data?.length) handleExportData(data);
                }}
                className="btn btn-lg float-end btn-primary px-3 h-100"
                disabled={requiredPermissions.some((permission) =>
                  checkPermission(roleRightsData, permission)
                )}
              >
                Export
              </button>
            )}
          </div>
        </div>
      ) : null}
    {/* single select end */}
    {/* multi-select start */}
      {location.pathname === "/getsalaryreport" ? (
        <div className="mb-4 row gy-2">
          <div className="col-xxl-3 col-xl-4 col-sm-4">
            <Multiselect
              ref={multiselectRef}
              options={companyOption}
              selectedValues={selectedCompanyOptions}
              displayValue="name"
              placeholder={"Company"}
              defaultValue=""
              onSelect={(selectedList, selectedItem) =>
                handleSelect(
                  selectedList,
                  selectedItem,
                  companyOption,
                  setSelectedCompanyOptions,
                  setValue,
                  "company_id"
                )
              }
              onRemove={(selectedList, removedItem) =>
                handleRemove(
                  selectedList,
                  removedItem,
                  setSelectedCompanyOptions,
                  setValue,
                  "company_id"
                )
              }
              onChange={(e) => {
                setSelectFilter(e);
                setFillter((prevState) => ({
                  ...prevState,
                  selectFillter: e?.value,
                }));
              }}
              value={selectFilter}
              avoidHighlightFirstOption={true}
              hidePlaceholder={true}
              showCheckbox={true}
              name="company_id"
              id="company_id"
              {...register("company_id")}
              style={{
                searchBox: {
                  height:
                    selectedCompanyOptions?.length >= 3 ? "100px" : "40px",
                  overflowY:
                    selectedCompanyOptions?.length >= 3 ? "scroll" : "auto",
                },
              }}
            />
          </div>
          <div className="col-xxl-3 col-xl-4 col-sm-4">
            <Multiselect
              ref={multiselectRef}
              options={locationOption}
              selectedValues={selectedLocationOptions}
              displayValue="name"
              placeholder={"Location"}
              defaultValue=""
              onSelect={(selectedList, selectedItem) =>
                handleSelect(
                  selectedList,
                  selectedItem,
                  locationOption,
                  setSelectedLocationOptions,
                  setValue,
                  "location_id"
                )
              }
              onRemove={(selectedList, removedItem) =>
                handleRemove(
                  selectedList,
                  removedItem,
                  setSelectedLocationOptions,
                  setValue,
                  "location_id"
                )
              }
              onChange={(e) => {
                setSelectFilter(e);
                setFillter((prevState) => ({
                  ...prevState,
                  selectFillter: e?.value,
                }));
              }}
              value={selectFilter}
              avoidHighlightFirstOption={true}
              hidePlaceholder={true}
              showCheckbox={true}
              name="location_id"
              id="location_id"
              {...register("location_id")}
              style={{
                searchBox: {
                  height:
                    selectedLocationOptions?.length >= 3 ? "100px" : "40px",
                  overflowY:
                    selectedLocationOptions?.length >= 3 ? "scroll" : "auto",
                },
              }}
            />
          </div>
          <div className="col-xxl-3 col-xl-4 col-sm-4">
            <Multiselect
              ref={multiselectRef}
              options={vendorOption}
              selectedValues={selectedVendorOptions}
              displayValue="name"
              placeholder={"Vendor"}
              defaultValue=""
              onSelect={(selectedList, selectedItem) =>
                handleSelect(
                  selectedList,
                  selectedItem,
                  vendorOption,
                  setSelectedVendorOptions,
                  setValue,
                  "vendor_id"
                )
              }
              onRemove={(selectedList, removedItem) =>
                handleRemove(
                  selectedList,
                  removedItem,
                  setSelectedVendorOptions,
                  setValue,
                  "vendor_id"
                )
              }
              onChange={(e) => {
                setSelectFilter(e);
                setFillter((prevState) => ({
                  ...prevState,
                  selectFillter: e?.value,
                }));
              }}
              value={selectFilter}
              avoidHighlightFirstOption={true}
              hidePlaceholder={true}
              showCheckbox={true}
              name="vendor_id"
              id="vendor_id"
              {...register("vendor_id")}
              style={{
                searchBox: {
                  height: selectedVendorOptions?.length >= 3 ? "100px" : "40px",
                  overflowY:
                    selectedVendorOptions?.length >= 3 ? "scroll" : "auto",
                },
              }}
            />
          </div>
          <div className="col-xxl-3 col-xl-4 col-sm-4">
            <Multiselect
              ref={multiselectRef}
              options={securityGuardOption}
              selectedValues={selectedSecurityGuardOptions}
              displayValue="name"
              placeholder={"Security Guard"}
              defaultValue=""
              onSelect={(selectedList, selectedItem) =>
                handleSelect(
                  selectedList,
                  selectedItem,
                  securityGuardOption,
                  setSelectedSecurityGuardOptions,
                  setValue,
                  "security_guard_id"
                )
              }
              onRemove={(selectedList, removedItem) =>
                handleRemove(
                  selectedList,
                  removedItem,
                  setSelectedSecurityGuardOptions,
                  setValue,
                  "security_guard_id"
                )
              }
              onChange={(e) => {
                setSelectFilter(e);
                setFillter((prevState) => ({
                  ...prevState,
                  selectFillter: e?.value,
                }));
              }}
              value={selectFilter}
              avoidHighlightFirstOption={true}
              hidePlaceholder={true}
              showCheckbox={true}
              name="security_guard_id"
              id="security_guard_id"
              {...register("security_guard_id")}
              style={{
                searchBox: {
                  height:
                    selectedSecurityGuardOptions?.length >= 3
                      ? "100px"
                      : "40px",
                  overflowY:
                    selectedSecurityGuardOptions?.length >= 3
                      ? "scroll"
                      : "auto",
                },
              }}
            />
          </div>
          <div className="col-xxl-3 col-xl-4 col-sm-4">
            <DateRangePicker
              maxDate={new Date()}
              disableClock
              value={value}
              onChange={onChange}
              format={"dd/MM/y"}
              clearIcon={null} 
            />
          </div>
          <div className="col-xxl-3 col-xl-4 col-sm-4">
            <button
            //   onClick={() => {
            //     setGlobalFilter(fillter);
            //   }}
              onClick={handleFilterSubmit}
              className="btn btn-lg btn-primary px-3 h-100"
            >
              Search
            </button>

            <button
            //   onClick={() => {
            //     setFillter({ selectFillter: "", search: "" });
            //     setGlobalFilter({ selectFillter: "", search: "" });
            //     setSelectFilter(null);
            //   }}
            onClick={handleFilterClear}
              style={{ marginLeft: "10px" }}
              className="btn btn-lg btn-primary px-3 h-100"
            >
              Clear
            </button>
          </div>

          <div className="col-xxl-3 col-xl-12 col-sm-12">
            {!isExport ? (
              <button
                onClick={() => {
                  toggleFormFillrter(true);
                }}
                className="btn btn-lg float-end btn-primary px-3 h-100"
                disabled={requiredPermissions.some((permission) =>
                  checkPermission(roleRightsData, permission)
                )}
              >
                Add
              </button>
            ) : (
              <button
                onClick={() => {
                  if (data?.length) handleExportData(data);
                }}
                className="btn btn-lg float-end btn-primary px-3 h-100"
                disabled={requiredPermissions.some((permission) =>
                  checkPermission(roleRightsData, permission)
                )}
              >
                Export
              </button>
            )}
          </div>
        </div>
      ) : null}
      {/* multi-select end */}
    </>
  );
};

export default Listfilter;
