import React, { useEffect, useState } from "react";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { axiosInstance } from "../../utils/commonInstance";
import { showToast } from "../../hooks/showToast";
import { checkObjectForNullUndefinedOrEmpty } from "../../utils/commonFunction";
import { BackButtonModal } from "../../components/BackButtonModal";
import DetectMyLocation from "./DetectMyLocation";
import "leaflet-control-geocoder/dist/Control.Geocoder.css";
import L from "leaflet";
import "leaflet-control-geocoder";

const schema = yup.object().shape({
  company_id: yup.string().required("Please select Company"),
  location_id: yup.string().required("Please select Location"),
  checkpoint_name: yup
    .string()
    .required("Please enter checkpoint name")
    .max(30, "Maximum 30 characters is allowed only")
    .test("no-spaces-at-ends", "Please Enter Valid Input", (value) => {
      if (!value) return true; // Skip empty values (use required() to handle this)
      return value.trim() === value;
    }),
  checkpoint_longitude: yup
    .string()
    .required("Kindly enter Longitude")
    // .max(20, "Maximum 20 character is allowed")
    // .test("no-spaces-at-ends", "Please Enter Valid Input", (value) => {
    //   if (!value) return true; // Skip empty values (use required() to handle this)
    //   return value.trim() === value;
    // }),
    .test("is-valid-longitude", "Please enter a valid Longitude", (value) => {
      if (!value) return true;
      const longitude = parseFloat(value);
      return !isNaN(longitude) && longitude >= -180 && longitude <= 180;
    }),
  checkpoint_latitude: yup
    .string()
    .required("Kindly enter Latitude")
    // .max(20, "Maximum 20 character is allowed")
    // .test("no-spaces-at-ends", "Please Enter Valid Input", (value) => {
    //   if (!value) return true; // Skip empty values (use required() to handle this)
    //   return value.trim() === value;
    // }),
    .test("is-valid-latitude", "Please enter a valid Latitude", (value) => {
      if (!value) return true;
      const latitude = parseFloat(value);
      return !isNaN(latitude) && latitude >= -90 && latitude <= 90;
    }),
});

const CheckPointForm = ({
  toggleFormFillrter,
  manualFetchData,
  setGlobalFilter,
  isView,
  isEdit,
  previousData,
}) => {
  const [companyOption, setCompanyOption] = useState([]);
  const [locationOption, setLocationOption] = useState([]);

  const [modal, setModal] = useState({
    isOpen: false,
  });

  const handleModalopen = () => {
    setModal((prevData) => ({
      ...prevData,
      isOpen: true,
    }));
  };

  const handleClose = () => {
    setModal((prevData) => ({
      ...prevData,
      isOpen: false,
    }));
  };

  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
    watch,
    reset,
    setValue,
  } = useForm({
    resolver: yupResolver(schema),
    mode: "all",
  });

  useEffect(() => {
    async function fetchData() {
      // You can await here
      const response = await axiosInstance.get(`web/companydropdown`);
      setCompanyOption(response?.data?.data);
      // ...
    }
    fetchData();
  }, []);

  const selectedCompanyId = watch("company_id");
  useEffect(() => {
    if (selectedCompanyId) {
      // Fetch locations based on selected company_id if needed
      async function fetchLocations() {
        const response = await axiosInstance.get(
          `web/locationdata/${selectedCompanyId}`
        );
        if (!response?.data?.error) {
          setLocationOption(response?.data?.data);
        } else {
          setLocationOption([]);
          showToast(response?.data?.message, "error");
        }
      }
      fetchLocations();
    }
  }, [selectedCompanyId]);

  // useEffect(() => {
  //   async function fetchData() {
  //     // You can await here
  //     const response = await axiosInstance.get(`web/company`);
  //     setCompanyOption(response?.data?.data);
  //     // ...
  //   }
  //   fetchData();
  // }, []);

  const onSubmit = async (data) => {
    const endPont = isEdit
      ? `editcheckpoint/${previousData?.id}`
      : "addcheckpoint";

    try {
      // const response = await axios.post(process.env.REACT_APP_BACKEND_API_URL + "login", {...data,'mode_of_login':'Web'})
      const response = await axiosInstance.post(`web/${endPont}`, {
        ...data,
      });

      if (response?.data?.error) {
        showToast(response?.data?.message, "error");
        return false;
      }
      manualFetchData();
      toggleFormFillrter();
      showToast(response?.data?.message, "success");
    } catch (error) {
      showToast(error?.data?.message, "error");
    }
  };

  useEffect(() => {
    if (isView || isEdit) {
      setValue("company_id", previousData?.company_id);
      setValue("location_id", previousData?.location_id);
      setValue("checkpoint_name", previousData?.checkpoint_name);
      setValue("checkpoint_longitude", previousData?.checkpoint_longitude);
      setValue("checkpoint_latitude", previousData?.checkpoint_latitude);
      setValue("checkpoint_status", previousData?.is_active);
    }
  }, [
    isEdit,
    isView,
    previousData,
    setValue,
    companyOption,
    // locationOption
  ]);

  let result = checkObjectForNullUndefinedOrEmpty(watch());

  const [location, setLocation] = useState(
    isEdit || isView
      ? {
          lat: previousData?.checkpoint_latitude || 28.492030222676025,
          lng: previousData?.checkpoint_longitude || 77.08041849653965,
        }
      : { lat: 21.7679, lng: 78.8718 }
  );

  useEffect(() => {
    setValue("checkpoint_longitude", location?.lng);
    setValue("checkpoint_latitude", location?.lat);
  }, [location, setValue]);

  const handleGeocode = (query) => {
    const geocoder = L.Control.Geocoder.nominatim();
    geocoder.geocode(query, (results) => {
      if (results && results.length > 0) {
        const { center } = results[0];
        setLocation(center);
      } else {
        alert("Location not found");
      }
    });
  };

  const dynamicStyles = isView
    ? `
    .leaflet-touch .leaflet-control-layers, .leaflet-touch .leaflet-bar {       
        // display: none !important;
        pointer-events: none;
        z-index: 9999;
        }    
    .leaflet-control-zoom-in, .leaflet-control-zoom-out {        
        // display: none !important;
        pointer-events: none;
        z-index: 1;
        }
    .leaflet-pane .leaflet-marker-pane {
        pointer-events: none;
        z-index: 9999;
      }
    .leaflet-pane .leaflet-marker-pane img {
        pointer-events: none;
        z-index: 1;
      }
    `
    : ``;

  return (
    <>
      <section className="userlist-newuser content mobile">
        <div className="d-flex justify-content-end align-items-center mb-4 gap-3 flex-wrap">
          <h3 className="mb-0">
            <strong>
              {isEdit ? "Update" : isView ? "View" : "Add"} Checkpoint Details
            </strong>
          </h3>
          <button
            onClick={() =>
              result && !isView ? handleModalopen() : toggleFormFillrter()
            }
            class="btn btn-lg btn-primary px-md-5 px-4 addlistuser"
          >
            Back
          </button>
        </div>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="user-details">
            <div className="card">
              <div className="row card-body justify-content-around py-md-5 py-4 align-items-center">
                <div className="col-lg-5 col-md-6 mb-3">
                  <div className="form-group">
                    <label htmlFor="company_id" className="form-label">
                      Select Company
                      <span className="text-danger">*</span>
                    </label>

                    <Controller
                      name="company_id"
                      control={control}
                      render={({ field }) => (
                        <select
                          className="form-select py-2 w-100"
                          {...field}
                          defaultValue=""
                          disabled={isView}
                          onChange={(e) => {
                            // Manually call the registered onChange method to ensure the value of company_id is updated
                            field.onChange(e);
                            setValue("location_id", "");
                          }}
                        >
                          <option value="" disabled>
                            Company
                          </option>
                          {companyOption?.map((ele) => (
                            <option value={ele?.id}>{ele?.company_name}</option>
                          ))}
                        </select>
                      )}
                    />

                    {errors?.company_id && (
                      <p className="text-danger">
                        {errors?.company_id?.message}
                      </p>
                    )}
                  </div>
                </div>

                <div className="col-lg-5 col-md-6 mb-3">
                  <div className="form-group">
                    <label htmlFor="location_id" className="form-label">
                      Select Location<span className="text-danger">*</span>
                    </label>

                    <Controller
                      name="location_id"
                      control={control}
                      render={({ field }) => (
                        <select
                          className="form-select py-2 w-100"
                          {...field}
                          defaultValue=""
                          disabled={isView}
                          onChange={(e) => {
                            // Manually call the registered onChange method to ensure the value of company_id is updated
                            const id = e?.target?.selectedOptions[0]?.value;

                            let item = locationOption.find(
                              (val) => val.id === parseInt(id)
                            );
                            setLocation({
                              lat: item?.latitude,
                              lng: item?.longitude,
                            });
                            field.onChange(e);
                            // handleGeocode(e?.target?.selectedOptions[0]?.label)
                          }}
                        >
                          <option value="" disabled>
                            Location
                          </option>
                          {locationOption?.map((ele) => (
                            <option value={ele?.id}>
                              {ele?.location_name}
                            </option>
                          ))}
                        </select>
                      )}
                    />

                    {errors?.location_id && (
                      <p className="text-danger">
                        {errors?.location_id?.message}
                      </p>
                    )}
                  </div>
                </div>

                <div className="col-lg-5 col-md-6">
                  <div className="form-group mb-3">
                    <label htmlFor="checkpoint_name" className="form-label">
                      CheckPoint Name<span className="text-danger">*</span>
                    </label>
                    <input
                      type="text"
                      className="form-control py-2"
                      id="checkpoint_name"
                      disabled={isView}
                      placeholder="Enter CheckPoint Name"
                      {...register("checkpoint_name")}
                    />
                    {errors.checkpoint_name && (
                      <p className="text-danger">
                        {errors.checkpoint_name.message}
                      </p>
                    )}
                  </div>
                </div>

                <div className="col-lg-5 col-md-6">
                  <div className="form-group mb-3">
                    <label
                      htmlFor="checkpoint_longitude"
                      className="form-label"
                    >
                      Company Longitude<span className="text-danger">*</span>
                    </label>
                    <input
                      type="text"
                      className="form-control py-2"
                      id="checkpoint_longitude"
                      placeholder="Enter Company Longitude"
                      {...register("checkpoint_longitude")}
                      disabled={isView}
                    />
                    {errors.checkpoint_longitude && (
                      <p className="text-danger">
                        {errors.checkpoint_longitude.message}
                      </p>
                    )}
                  </div>
                </div>

                <div className="col-lg-5 col-md-6">
                  <div className="form-group mb-3">
                    <label htmlFor="checkpoint_latitude" className="form-label">
                      Company Latitude<span className="text-danger">*</span>
                    </label>
                    <input
                      type="text"
                      className="form-control py-2"
                      id="checkpoint_latitude"
                      placeholder="Enter Company Latitude"
                      {...register("checkpoint_latitude")}
                      disabled={isView}
                    />
                    {errors.checkpoint_latitude && (
                      <p className="text-danger">
                        {errors.checkpoint_latitude.message}
                      </p>
                    )}
                  </div>
                </div>

                <div className="col-lg-5 col-md-6">
                  <div className="form-group mt-md-3 mb-3">
                    <div className="form-check">
                      <input
                        className="form-check-input mt-1"
                        type="checkbox"
                        id="checkpoint_status"
                        {...register("checkpoint_status")}
                        disabled={isView}
                        defaultChecked={!isView || !isEdit}
                      />
                      <label
                        className="form-check-label mb-0"
                        htmlFor="checkpoint_status"
                      >
                        IS ACTIVE
                      </label>
                    </div>
                  </div>
                </div>

                <div className="col-12 text-center mt-lg-5 mt-4 d-sm-inline-flex justify-content-sm-center d-grid gap-3">
                  <button
                    className="btn btn-lg btn-primary px-5"
                    type="submit"
                    disabled={isView}
                  >
                    {isEdit ? "UPDATE" : "SUBMIT"}
                  </button>
                  <button
                    class="btn btn-lg btn-primary px-5 mx-xl-4"
                    type="button"
                    disabled={isView || isEdit}
                    onClick={() => {
                      reset();
                      setLocationOption([]);
                    }}
                  >
                    CLEAR
                  </button>
                </div>
              </div>
            </div>
          </div>
        </form>        
        <div className={isView ? "disabled-div" : ""}>
          <style>{dynamicStyles}</style>
          <DetectMyLocation location={location} setLocation={setLocation} />
        </div>
        <BackButtonModal
          isOpen={modal?.isOpen}
          handleClose={handleClose}
          toggleFormFillrter={toggleFormFillrter}
        />
      </section>
    </>
  );
};

export default CheckPointForm;
