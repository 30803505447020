export const UserColumns = [
  {
    accessorKey: "guard_code",
    header: "Guard Code",
    enableSorting: false,
  },
  {
    accessorKey: "username",
    header: "Name",
    enableSorting: false,
  },
  {
    accessorKey: "email",
    header: "Email",
    enableSorting: false,
  },
  {
    accessorKey: "mobile_no",
    header: "Mobile No",
    enableSorting: false,
  },
  {
    accessorKey: "date_of_joining",
    header: "Date of joining",
    enableSorting: false,
  },
  {
    accessorKey: "role_name",
    header: "Role",
    enableSorting: false,
  },
  {
    accessorKey: "company_name",
    header: "Company",
    enableSorting: false,
    Cell: ({ cell, row }) => (
        <div>
          {row?.original?.company?.map((item) => item?.company_name).join(", ")}
        </div>
      ),
  },
  {
    accessorKey: "location_name",
    header: "Location",
    enableSorting: false,
    // Cell: ({ cell, row }) => <div>{row?.original?.location_name}</div>,
    Cell: ({ cell, row }) => (
        <div>
          {row?.original?.location?.map((item) => item?.location_name).join(", ")}
        </div>
      ),
  },
  {
    accessorKey: "designation_name",
    header: "Designation",
    enableSorting: false,
  },
  {
    accessorKey: "checkpoints",
    header: "Assigned Checkpoint",
    enableSorting: false,
    Cell: ({ cell, row }) => (
      <div>
        {row?.original?.checkpoints?.map((item) => item?.name).join(", ")}
      </div>
    ),
  },
  {
    accessorKey: "vendor_name",
    header: "Vendor Name",
    enableSorting: false,
  },
  {
    accessorKey: "preview",
    header: "Document",
    enableSorting: false,
  },
  {
    accessorKey: "salary",
    header: "Salary",
    enableSorting: false,
  },
  {
    accessorKey: "shift_name",
    header: "Shift",
    enableSorting: false,
    Cell: ({ cell, row }) => <div>{row?.original?.shift?.shift_name}</div>,
  },
  {
    accessorKey: "is_active",
    header: "Status",
    enableSorting: false,
    Cell: ({ cell, row }) => (
      <div>{row?.original?.is_active === 1 ? "Active" : "InActive"}</div>
    ),
  },
  {
    accessorKey: "action",
    header: "Action",
    enableSorting: false,
  },
];

export const LocationColumns = [
  {
    accessorKey: "location_name",
    header: "Location Name",
    enableSorting: false,
    Cell: ({ cell, row }) => <div>{row?.original?.location_name}</div>,
  },

  {
    accessorKey: "location_is_active",
    header: "Status",
    enableSorting: false,
    Cell: ({ cell, row }) => (
      <div>
        {row?.original?.location_is_active === 1 ? "Active" : "InActive"}
      </div>
    ),
  },
  {
    accessorKey: "created_at",
    header: "Date",
    enableSorting: false,
    Cell: ({ cell, row }) => (
      <div>
        {row?.original?.created_at
          ? new Date(row.original.created_at).toLocaleDateString("en-GB")
          : "-"}
      </div>
    ),
  },
  {
    accessorKey: "action",
    header: "Action",
    enableSorting: false,
  },
];

export const VendorColumns = [
  {
    accessorKey: "vendor_name",
    header: "Name",
    enableSorting: false,
  },
  {
    accessorKey: "email",
    header: "Email",
    enableSorting: false,
  },

  {
    accessorKey: "mobile_no",
    header: "Mobile No",
    enableSorting: false,
  },

  {
    accessorKey: "gst_no",
    header: "GST No",
    enableSorting: false,
  },

  {
    accessorKey: "tan_no",
    header: "TAN No",
    enableSorting: false,
  },

  {
    accessorKey: "pan_no",
    header: "PAN No",
    enableSorting: false,
  },
  {
    accessorKey: "epfo_no",
    header: "EPFO No",
    enableSorting: false,
  },
  {
    accessorKey: "security_certificate_no",
    header: "Security Certificate No",
    enableSorting: false,
  },
  {
    accessorKey: "preview",
    header: "Agreement",
    enableSorting: false,
  },
  {
    accessorKey: "is_active",
    header: "Status",
    enableSorting: false,
    Cell: ({ cell, row }) => (
      <div>{row?.original?.is_active === 1 ? "Active" : "InActive"}</div>
    ),
  },
  {
    accessorKey: "action",
    header: "Action",
    enableSorting: false,
  },
];

export const DesignationColumns = [
  {
    accessorKey: "designation",
    header: "Designation",
    enableSorting: false,
  },
  {
    accessorKey: "company_name",
    header: "Company Name",
    enableSorting: false,
  },
  {
    accessorKey: "location_name",
    header: "Location",
    enableSorting: false,
  },
  {
    accessorKey: "vendor_name",
    header: "Vendor Name",
    enableSorting: false,
  },
  {
    accessorKey: "salary",
    header: "Total Salary",
    enableSorting: false,
  },
  {
    accessorKey: "action",
    header: "Action",
    enableSorting: false,
  },
];

export const CheckpointColumns = [
  {
    accessorKey: "company_name",
    header: "Company Name",
    enableSorting: false,
  },
  {
    accessorKey: "location_name",
    header: "Location",
    enableSorting: false,
  },
  {
    accessorKey: "checkpoint_name",
    header: "Checkpoint Name",
    enableSorting: false,
  },
  {
    accessorKey: "checkpoint_longitude",
    header: "Longitude",
    enableSorting: false,
  },
  {
    accessorKey: "checkpoint_latitude",
    header: "Latitude",
    enableSorting: false,
  },
  {
    accessorKey: "is_active",
    header: "Status",
    enableSorting: false,
    Cell: ({ cell, row }) => (
      <div>{row?.original?.is_active === 1 ? "Active" : "InActive"}</div>
    ),
  },
  {
    accessorKey: "created_at",
    header: "Date",
    enableSorting: false,
    Cell: ({ cell, row }) => (
      <div>
        {row?.original?.created_at
          ? new Date(row.original.created_at).toLocaleDateString("en-GB")
          : "-"}
      </div>
    ),
  },
  {
    accessorKey: "action",
    header: "Action",
    enableSorting: false,
  },
];

export const ShiftColumns = [
  {
    accessorKey: "company_name",
    header: "Company Name",
    enableSorting: false,
  },
  {
    accessorKey: "location_name",
    header: "Location",
    enableSorting: false,
  },
  {
    accessorKey: "shift_name",
    header: "Shift Name",
    enableSorting: false,
  },
  {
    accessorKey: "start_time",
    header: "Start Time",
    enableSorting: false,
  },
  {
    accessorKey: "end_time",
    header: "End Time",
    enableSorting: false,
  },
  {
    accessorKey: "created_at",
    header: "Date",
    enableSorting: false,
    Cell: ({ cell, row }) => (
      <div>
        {row?.original?.created_at
          ? new Date(row?.original?.created_at).toLocaleDateString("en-GB")
          : "-"}
      </div>
    ),
  },
  {
    accessorKey: "is_active",
    header: "Status",
    enableSorting: false,
    Cell: ({ cell, row }) => (
      <div>{row?.original?.is_active === 1 ? "Active" : "InActive"}</div>
    ),
  },
  {
    accessorKey: "action",
    header: "Action",
    enableSorting: false,
  },
];

export const CompanyColumns = [
  {
    accessorKey: "company_name",
    header: "Company Name",
    enableSorting: false,
  },
  {
    accessorKey: "location_name",
    header: "Location Name",
    enableSorting: false,
  },
  {
    accessorKey: "company_longitude",
    header: "Company Longitude",
    enableSorting: false,
  },
  {
    accessorKey: "company_latitude",
    header: "Company Latitude",
    enableSorting: false,
  },

  {
    accessorKey: "created_at",
    header: "Date",
    enableSorting: false,
    Cell: ({ cell, row }) => (
      <div>
        {row?.original?.created_at
          ? new Date(row?.original?.created_at).toLocaleDateString("en-GB")
          : "-"}
      </div>
    ),
  },

  {
    accessorKey: "is_active",
    header: "Status",
    enableSorting: false,
    Cell: ({ cell, row }) => (
      <div>{row?.original?.is_active === 1 ? "Active" : "InActive"}</div>
    ),
  },
  {
    accessorKey: "action",
    header: "Action",
    enableSorting: false,
  },
];

export const CompanymasterColumns = [
  {
    accessorKey: "company_name",
    header: "Company Name",
    enableSorting: false,
  },
  {
    accessorKey: "is_active",
    header: "Status",
    enableSorting: false,
    Cell: ({ cell, row }) => (
      <div>{row?.original?.is_active === 1 ? "Active" : "InActive"}</div>
    ),
  },
  {
    accessorKey: "created_at",
    header: "Date",
    enableSorting: false,
    Cell: ({ cell, row }) => (
      <div>
        {row?.original?.created_at
          ? new Date(row.original.created_at).toLocaleDateString("en-GB")
          : "-"}
      </div>
    ),
  },
  {
    accessorKey: "action",
    header: "Action",
    enableSorting: false,
  },
];

export const RolesColumns = [
  {
    accessorKey: "name",
    header: "Role Name",
    enableSorting: false,
  },
  {
    accessorKey: "is_active",
    header: "Status",
    enableSorting: false,
    Cell: ({ cell, row }) => (
      <div>{row?.original?.is_active === 1 ? "Active" : "InActive"}</div>
    ),
  },

  {
    accessorKey: "created_at",
    header: "Date",
    enableSorting: false,
    Cell: ({ cell, row }) => (
      <div>
        {row?.original?.created_at
          ? new Date(row.original.created_at).toLocaleDateString("en-GB")
          : "-"}
      </div>
    ),
  },

  {
    accessorKey: "action",
    header: "Action",
    enableSorting: false,
  },
];

export const AppColumns = [
  {
    accessorKey: "app_version_code",
    header: "App Version Code",
    enableSorting: false,
  },
  {
    accessorKey: "app_version_no",
    header: "App Version No.",
    enableSorting: false,
    // Cell: ({ cell, row }) => (
    //   <div>{row?.original?.is_active === 1 ? "Active" : "InActive"}</div>
    // ),
  },

  {
    accessorKey: "apk_name",
    header: "File Name",
    enableSorting: false,
    // Cell: ({ cell, row }) => (
    //   <div>
    //     {row?.original?.created_at
    //       ? new Date(row.original.created_at).toLocaleDateString("en-GB")
    //       : "-"}
    //   </div>
    // ),
  },

  //   {
  //     accessorKey: "description",
  //     header: "Description",
  //     enableSorting: false,
  //   },
  {
    accessorKey: "from",
    header: "From Date",
    enableSorting: false,
  },
  {
    accessorKey: "to",
    header: "To Date",
    enableSorting: false,
  },
];

export const AttendanceReportsColumns = [
  {
    accessorKey: "guard_code",
    header: "Guard code",
    enableSorting: false,
    Cell: ({ cell, row }) => (
      <div>{row?.original?.guard_code ? row?.original?.guard_code : "-"}</div>
    ),
  },
  {
    accessorKey: "username",
    header: "Name",
    enableSorting: false,
    Cell: ({ cell, row }) => (
      <div>{row?.original?.username ? row?.original?.username : "-"}</div>
    ),
  },
  {
    accessorKey: "company_name",
    header: "Company Name",
    enableSorting: false,
    Cell: ({ cell, row }) => (
      <div>
        {row?.original?.company_name ? row?.original?.company_name : "-"}
      </div>
    ),
  },
  {
    accessorKey: "location_name",
    header: "Location",
    enableSorting: false,
    Cell: ({ cell, row }) => (
      <div>
        {row?.original?.location_name ? row?.original?.location_name : "-"}
      </div>
    ),
  },
  {
    accessorKey: "designation_name",
    header: "Designation",
    enableSorting: false,
    Cell: ({ cell, row }) => (
      <div>
        {row?.original?.designation_name
          ? row?.original?.designation_name
          : "-"}
      </div>
    ),
  },

  {
    accessorKey: "checkin_time",
    header: "Check-in",
    enableSorting: false,
    Cell: ({ cell, row }) => (
      <div>
        {row?.original?.checkin_time ? row?.original?.checkin_time : "-"}
      </div>
    ),
  },
  {
    accessorKey: "checkout_time",
    header: "Check-out",
    enableSorting: false,
    Cell: ({ cell, row }) => (
      <div>
        {row?.original?.checkout_time ? row?.original?.checkout_time : "-"}
      </div>
    ),
  },
  {
    accessorKey: "worked_hours",
    header: "Worked hours",
    enableSorting: false,
    Cell: ({ cell, row }) => (
      <div>
        {row?.original?.worked_hours ? row?.original?.worked_hours : "-"}
      </div>
    ),
  },
  {
    accessorKey: "total_in_out_duration",
    header: "Away Time",
    enableSorting: false,
    Cell: ({ cell, row }) => (
      <div>
        {row?.original?.total_in_out_duration
          ? row?.original?.total_in_out_duration
          : "-"}
      </div>
    ),
  },
//   {
//     accessorKey: "checkout_pointname",
//     header: "Assign Checkpoint",
//     enableSorting: false,
//     Cell: ({ cell, row }) => (
//       <div>
//         {row?.original?.checkout_pointname
//           ? row?.original?.checkout_pointname
//           : "-"}
//       </div>
//     ),
//   },
  {
    accessorKey: "checkpoints_name",
    header: "Assign Checkpoint",
    enableSorting: false,
    Cell: ({ cell, row }) => (
        <div>{row?.original?.checkpoints_name ? row?.original?.checkpoints_name : "-"}</div>    
    ),
  },

  {
    accessorKey: "shift_name",
    header: "Shift",
    enableSorting: false,
    Cell: ({ cell, row }) => (
      <div>{row?.original?.shift_name ? row?.original?.shift_name : "-"}</div>
    ),
  },
  {
    accessorKey: "date",
    header: "Date",
    enableSorting: false,
    Cell: ({ cell, row }) => (
      <div>
        {row?.original?.date
          ? new Date(row?.original?.date).toLocaleDateString("en-GB")
          : "-"}
      </div>
    ),
  },
  {
    accessorKey: "preview",
    header: "Images",
    enableSorting: false,
  },
  // {
  //   accessorKey: "map",
  //   header: "Map",
  //   enableSorting: false,
  // },
];

export const GetLockReportsColumns = [
//   {
//     accessorKey: "guardcode",
//     header: "Guard code",
//     enableSorting: false,
//     Cell: ({ cell, row }) => (
//       <div>{row?.original?.guardcode ? row?.original?.guardcode : "-"}</div>
//     ),
//   },
  {
    accessorKey: "username",
    header: "Guard Name (Guard Code)",
    enableSorting: false,
    Cell: ({ cell, row }) => (
      <div>{row?.original?.username ? row?.original?.username : "-"}</div>
    ),
  },
  {
    accessorKey: "company_name",
    header: "Company Name",
    enableSorting: false,
    Cell: ({ cell, row }) => (
      <div>
        {row?.original?.company_name ? row?.original?.company_name : "-"}
      </div>
    ),
  },
  {
    accessorKey: "location_name",
    header: "Location",
    enableSorting: false,
    Cell: ({ cell, row }) => (
      <div>
        {row?.original?.location_name ? row?.original?.location_name : "-"}
      </div>
    ),
  },
  {
    accessorKey: "designation_name",
    header: "Designation",
    enableSorting: false,
    Cell: ({ cell, row }) => (
      <div>
        {row?.original?.designation_name
          ? row?.original?.designation_name
          : "-"}
      </div>
    ),
  },

  {
    accessorKey: "gate_close_time",
    header: "Gate close",
    enableSorting: false,
    Cell: ({ cell, row }) => <div>{row?.original?.gate_close_time}</div>,
  },
  {
    accessorKey: "other_guard_name",
    header: "Other Guard on Duty",
    enableSorting: false,
    Cell: ({ cell, row }) => (
      <div>
        {row?.original?.other_guard_name
          ? row?.original?.other_guard_name
          : "-"}
      </div>
    ),
  },

  {
    accessorKey: "checkpoint",
    header: "Assigned Checkpoint",
    enableSorting: false,
    Cell: ({ cell, row }) => (
      <div>{row?.original?.checkpoint ? row?.original?.checkpoint : "-"}</div>
    ),
  },
  {
    accessorKey: "gate_close_date",
    header: "Date",
    enableSorting: false,
    Cell: ({ cell, row }) => (
      <div>
        {row?.original?.gate_close_date
          ? new Date(row.original.gate_close_date).toLocaleDateString("en-GB")
          : "-"}
      </div>
    ),
  },
  {
    accessorKey: "preview",
    header: "Images",
    enableSorting: false,
  },
];

export const TresspasingReportsColumns = [
//   {
//     accessorKey: "guard_code",
//     header: "Guard code",
//     enableSorting: false,
//     Cell: ({ cell, row }) => (
//       <div>{row?.original?.guard_code ? row?.original?.guard_code : "-"}</div>
//     ),
//   },
  {
    accessorKey: "username",
    header: "Guard Name (Guard Code)",
    enableSorting: false,
    Cell: ({ cell, row }) => (
      <div>{row?.original?.username ? row?.original?.username : "-"}</div>
    ),
  },
  {
    accessorKey: "company_name",
    header: "Company Name",
    enableSorting: false,
    Cell: ({ cell, row }) => (
      <div>
        {row?.original?.company_name ? row?.original?.company_name : "-"}
      </div>
    ),
  },
  {
    accessorKey: "location_name",
    header: "Location",
    enableSorting: false,
    Cell: ({ cell, row }) => (
      <div>
        {row?.original?.location_name ? row?.original?.location_name : "-"}
      </div>
    ),
  },
  {
    accessorKey: "designation_name",
    header: "Designation",
    enableSorting: false,
    Cell: ({ cell, row }) => (
      <div>
        {row?.original?.designation_name
          ? row?.original?.designation_name
          : "-"}
      </div>
    ),
  },

  {
    accessorKey: "alert_created_time",
    header: "Time",
    enableSorting: false,
  },

  {
    accessorKey: "alert_created_date",
    header: "Date",
    enableSorting: false,
  },

  {
    accessorKey: "alert_message",
    header: "Note",
    enableSorting: false,
  },

  {
    accessorKey: "voicenote",
    header: "Voice Note",
    enableSorting: false,
  },
  // {
  //   accessorKey: "map",
  //   header: "Map",
  //   enableSorting: false,
  // },
];

// export const SalaryReportsColumns = [
//   {
//     accessorKey: "guard_code",
//     header: "Guard code",
//     enableSorting: false,
//     Cell: ({ cell, row }) => (
//       <div>
//         {row?.original?.guard_code
//           ? row?.original?.guard_code
//           : "-"}
//       </div>
//     ),
//   },
//   {
//     accessorKey: "user_name",
//     header: "Name",
//     enableSorting: false,
//     Cell: ({ cell, row }) => (
//       <div>
//         {row?.original?.user_name
//           ? row?.original?.user_name
//           : "-"}
//       </div>
//     ),
//   },
//   {
//     accessorKey: "company_name",
//     header: "Company Name",
//     enableSorting: false,
//     Cell: ({ cell, row }) => (
//       <div>
//         {row?.original?.company_name
//           ? row?.original?.company_name
//           : "-"}
//       </div>
//     ),
//   },
//   {
//     accessorKey: "location_name",
//     header: "Location",
//     enableSorting: false,
//     Cell: ({ cell, row }) => (
//       <div>
//         {row?.original?.location_name
//           ? row?.original?.location_name
//           : "-"}
//       </div>
//     ),
//   },
//   {
//     accessorKey: "designation_name",
//     header: "Designation",
//     enableSorting: false,
//     Cell: ({ cell, row }) => (
//       <div>
//         {row?.original?.designation_name
//           ? row?.original?.designation_name
//           : "-"}
//       </div>
//     ),
//   },

//   {
//     accessorKey: "vendor_name",
//     header: "Vendor",
//     enableSorting: false,
//     Cell: ({ cell, row }) => (
//       <div>
//         {row?.original?.vendor_name
//           ? row?.original?.vendor_name
//           : "-"}
//       </div>
//     ),
//   },

//   {
//     accessorKey: "date_of_joining",
//     header: "Date of Joining",
//     enableSorting: false,

//   },

//   {
//     accessorKey: "total_days_of_month",
//     header: "Total Days in Month",
//     enableSorting: false,

//   },

//   {
//     accessorKey: "month_year",
//     header: "Present Month-Year",
//     enableSorting: false,
//   },
//   {
//     accessorKey: "salary",
//     header: "Salary",
//     enableSorting: false,
//   },
//   {
//     accessorKey: "net_pay",
//     header: "Net Pay",
//     enableSorting: false,
//   },
//   {
//     accessorKey: "total_shift",
//     header: "Total Shift worked/Month",
//     enableSorting: false,
//   },

//   {
//     accessorKey: "total_worked_hours",
//     header: "Total Worked Hours",
//     enableSorting: false,

//   },
// ];

export const SalaryReportsColumns = [
  {
    accessorKey: "guard_code",
    header: "Guard Code",
    enableSorting: false,
    Cell: ({ cell, row }) => (
      <div>{row?.original?.guard_code ? row?.original?.guard_code : "-"}</div>
    ),
  },
  {
    accessorKey: "security_guard",
    header: "Security Guard",
    enableSorting: false,
    Cell: ({ cell, row }) => (
      <div>
        {row?.original?.security_guard ? row?.original?.security_guard : "-"}
      </div>
    ),
  },
  {
    accessorKey: "company_location",
    header: "Company - Location",
    enableSorting: false,
    Cell: ({ cell, row }) => (
      <div>
        {row?.original?.company_location
          ? row?.original?.company_location
          : "-"}
      </div>
    ),
  },
  {
    accessorKey: "designation_name",
    header: "Designation",
    enableSorting: false,
    Cell: ({ cell, row }) => (
      <div>
        {row?.original?.designation_name
          ? row?.original?.designation_name
          : "-"}
      </div>
    ),
  },

  {
    accessorKey: "vendor_name",
    header: "Vendor Name",
    enableSorting: false,
    Cell: ({ cell, row }) => (
      <div>{row?.original?.vendor_name ? row?.original?.vendor_name : "-"}</div>
    ),
  },
  {
    accessorKey: "salary",
    header: "Salary",
    enableSorting: false,
  },
  {
    accessorKey: "total_shift",
    header: "Total Shift",
    enableSorting: false,
  },

  {
    accessorKey: "total_worked_hours",
    header: "Total Worked Hours",
    enableSorting: false,
    Footer: () => (
      <div className="text-center">
        <h5>
          <b>Total Amount</b>
        </h5>
      </div>
    ),
  },
{
    accessorKey: "net_pay",
    header: "Net Pay",
    enableSorting: false,
    Footer: ({ table }) => {
      const rows = table?.getRowModel?.().rows || [];
      const total_net_pay = rows.reduce(
        (sum, row) => sum + (row?.original?.net_pay || 0),
        0
      ).toFixed(2);
      return (
        <div>
          <h5>
            <b>{total_net_pay}</b>
          </h5>
        </div>
      );
    },
  },
];
